import React, { useEffect, useMemo, useRef } from "react";
import { Port } from "../../types";
import { Marker, Popup, Tooltip } from "react-leaflet";
import {
  PORT_ICON_DOCKING_SVG,
  PORT_ICON_MARINA_SVG,
  PORT_ICON_SMALL_PORT_SVG,
  PORT_URL,
} from "../../config/const";
import slugify from "slugify";
import styled from "styled-components";

interface PortMarkerProps {
  port: Port;
  portIcon: L.Icon<{
    iconUrl: any;
    iconRetinaUrl: any;
    iconSize: L.Point;
  }>;
  zoomLevel: number;
}

export const PortMarker: React.FC<PortMarkerProps> = ({
  port,
  portIcon,
  zoomLevel,
}) => {
  const markerRef = useRef<any>();
  const [onHover, setOnHover] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(zoomLevel > 7);

  useEffect(() => {
    if (zoomLevel > 7) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [zoomLevel]);

  const eventHandlers = useMemo(
    () => ({
      mouseover() {
        setOnHover(true);
      },
      mouseout() {
        setOnHover(false);
      },
    }),
    []
  );

  return (
    <Marker
      ref={markerRef}
      position={[port.lat, port.lng]}
      icon={portIcon}
      eventHandlers={eventHandlers}
    >
      {showTooltip && onHover && (
        <Tooltip direction="top" offset={[4, -20]} permanent>
          {port.name.toUpperCase()}
        </Tooltip>
      )}

      <StyledPop>
        <PopupContent>
          <City>
            <LocationIcon
              src={
                port.categoria?.toLocaleLowerCase().includes("marina")
                  ? PORT_ICON_MARINA_SVG
                  : port.categoria?.toLocaleLowerCase().includes("mooring")
                  ? PORT_ICON_DOCKING_SVG
                  : PORT_ICON_SMALL_PORT_SVG
              }
              title={port.city}
            />
            {port.categoria ? port.categoria : port.name}
          </City>
          <Title>{port.name.toUpperCase()}</Title>
          <Link
            href={`${PORT_URL}${slugify(port.name, { lower: true })}`}
            title={port.name}
          >
            Go to data sheet
            <span>{">"}</span>
          </Link>
        </PopupContent>
      </StyledPop>
    </Marker>
  );
};

const PopupContent = styled.div`
  font-family: ${({ theme }) => theme.general.defaultFont};
  display: flex;
  flex-direction: column;
`;

const Title = styled.h4`
  color: ${({ theme }) => theme.colors.primary} !important;
  padding: 10px 0;
  margin: 0;
`;

const City = styled.p`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0 !important;
  align-items: center;
`;

const LocationIcon = styled.img`
  color: ${({ theme }) => theme.colors.secondary} !important;
  height: 1.5rem;
  width: 1.5rem;
`;

const Link = styled.a`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.primary} !important;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary} !important;
    gap: 0.5rem;
  }
`;

const StyledPop = styled(Popup)`
  .leaflet-popup-content-wrapper {
    min-width: 18vw !important;
  }
`;
