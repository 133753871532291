import React from "react";
import styled from "styled-components";
import { MapStore } from "../../stores/Map";
import theme from "../../theme";
import { IconButton } from "../IconButton/IconButton";
import { LegendElement } from "./LegendElement/LegendElement";

interface LegendProps {
  showLegend: boolean;
  isDesktop: boolean;
  mapStore: MapStore;
  toggleShowLegend: () => void;
}
export const Legend: React.FC<LegendProps> = ({
  showLegend,
  isDesktop,
  mapStore,
  toggleShowLegend,
}) => {
  return (
    <LegendContainer showLegend={showLegend} isDesktop={isDesktop}>
      <LegendTitle>
        Legenda
        <IconButton
          icon={"FaTimesCircle"}
          onClick={toggleShowLegend}
          color={theme.colors.secondary}
        />
      </LegendTitle>
      <LegendContentContainer>
        {mapStore.portsLayer.checked && (
          <LegendElement layer={mapStore.portsLayer} />
        )}
        {mapStore.selectedLayers.map(
          (layer) =>
            layer.checked &&
            !!layer.legend && <LegendElement key={layer.name} layer={layer} />
        )}
        {mapStore.vesselsLayer.map(
          (layer) =>
            layer.checked &&
            !!layer.legend &&
            layer.visible && <LegendElement key={layer.name} layer={layer} />
        )}
      </LegendContentContainer>
    </LegendContainer>
  );
};

const LegendContainer = styled.div<{ showLegend: boolean; isDesktop: boolean }>`
  height: 100%;
  overflow-y: hidden;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.white};
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.isDesktop
      ? "position: relative;"
      : "position: absolute; top: 0px; left:0px; z-index: 1001;"};

  width: ${(props) =>
    props.showLegend ? (props.isDesktop ? "35%" : "50%") : 0};
  opacity: ${(props) => (props.showLegend ? 1 : 0)};
`;

const LegendTitle = styled.h4`
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.colors.primary};
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 !important;
`;

const LegendContentContainer = styled.div`
  height: 90%;
  overflow-y: auto;
`;
