import React, { useCallback, useEffect, useState } from "react";
import { Layer } from "../../../types";
import { GeoJSON } from "react-leaflet";

interface GeoJSONLayerProps {
  layer: Layer;
}

export const GeoJSONLayer: React.FC<GeoJSONLayerProps> = ({ layer }) => {
  const [layerData, setLayerData] = useState(undefined);

  const fetchLayerData = useCallback(async () => {
    try {
      await fetch(layer.data)
        .then(async function (response) {
          const layerData = await response.json();
          setLayerData(layerData);
        })
        .catch(function (error) {
          console.log(error);
          setLayerData(undefined);
        });
    } catch (error) {
      console.log(error);
      setLayerData(undefined);
    }
  }, [layer.data]);

  useEffect(() => {
    fetchLayerData();
  }, [fetchLayerData]);

  return layer.checked && !!layerData ? (
    <GeoJSON
      key={layer.name}
      data={layerData}
      style={(feature) =>
        layer.customStyle
          ? { ...layer.customStyle(feature) }
          : { ...layer.style }
      }
      pointToLayer={layer.pointToLayer}
      onEachFeature={layer.onEachFeature}
      attribution={layer.attribution}
    />
  ) : null;
};
