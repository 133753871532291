import { useMapEvents } from "react-leaflet";

interface ZoomControlProps {
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>;
}

export const ZoomControl: React.FunctionComponent<ZoomControlProps> = ({
  setZoomLevel,
}) => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });

  return null;
};
