import { Layer } from "../types";
import env from "./env";
import L from "leaflet";
import keys from "./keys";
import { VesselsLayerLegendController } from "../components/Legend/VesselsLayerLegendController/VesselsLayerLegendController";

export const PORT_URL =
  env.ENV === "development" ? `${keys.BASE_URL}porti/` : "/porti/";

/**
 * PORT ICON START
 */
export const PORT_ICON_MARINA_SVG =
  env.ENV === "development"
    ? require("../assets/images/port_marker_marina.svg").default
    : `${keys.BASE_URL}/wp-content/ports-map/media/port_marker_marina.svg`;

export const PORT_ICON_MARINA = (zoomLevel: number) => {
  return new L.Icon({
    iconUrl: PORT_ICON_MARINA_SVG,
    iconRetinaUrl: PORT_ICON_MARINA_SVG,
    iconSize: new L.Point(zoomLevel * 3, zoomLevel * 6),
    iconAnchor: [zoomLevel, zoomLevel * 4],
    popupAnchor: [zoomLevel / 2, -zoomLevel * 4],
  });
};

export const PORT_ICON_SMALL_PORT_SVG =
  env.ENV === "development"
    ? require("../assets/images/port_marker_small_port.svg").default
    : `${keys.BASE_URL}/wp-content/ports-map/media/port_marker_small_port.svg`;

export const PORT_ICON_SMALL_PORT = (zoomLevel: number) => {
  return new L.Icon({
    iconUrl: PORT_ICON_SMALL_PORT_SVG,
    iconRetinaUrl: PORT_ICON_SMALL_PORT_SVG,
    iconSize: new L.Point(zoomLevel * 3, zoomLevel * 6),
    iconAnchor: [zoomLevel, zoomLevel * 4],
    popupAnchor: [zoomLevel / 2, -zoomLevel * 4],
  });
};

export const PORT_ICON_DOCKING_SVG =
  env.ENV === "development"
    ? require("../assets/images/port_marker_docking.svg").default
    : `${keys.BASE_URL}/wp-content/ports-map/media/port_marker_docking.svg`;

export const PORT_ICON_DOCKING = (zoomLevel: number) => {
  return new L.Icon({
    iconUrl: PORT_ICON_DOCKING_SVG,
    iconRetinaUrl: PORT_ICON_DOCKING_SVG,
    iconSize: new L.Point(zoomLevel * 3, zoomLevel * 6),
    iconAnchor: [zoomLevel, zoomLevel * 4],
    popupAnchor: [zoomLevel / 2, -zoomLevel * 4],
  });
};

/**
 * PORT ICON END
 */

/**
 * MUSEUMS ICON START
 */
const getIconCluster = (value: number) => {
  return L.divIcon({
    iconSize: new L.Point(40, 40),
    iconAnchor: new L.Point(20, 30),
    className: "custom-div-icon",
    html:
      '<div class="poi-cluster"><div class="pin"></div><div class="number">' +
      value +
      "</div></div>",
  });
};

// It was used for the second type of museum icon
// const getIconPoint = (value: number) => {
//   return L.divIcon({
//     iconSize: new L.Point(30, 20),
//     iconAnchor: new L.Point(15, 32),
//     className: "custom-div-icon",
//     html:
//       '<div class="poi-point"><div class="number">' + value + "</div></div>",
//   });
// };

/**
 * MUSEUMS ICON END
 */

export const PORTS_LAYER: Layer = {
  name: "ports",
  label: "Small ports and marinas",
  attribution: "",
  type: "geojson",
  data: "",
  checked: true,
  visible: true,
  legend: {
    colors: [
      {
        label:
          "<strong>Nautical tourism port (marina)</strong> - is part of a specially built and arranged sea/water area and coast for the provision of mooring services, accommodation of tourists in boats, and other services needed by tourists. Drinks, beverages, and food services are provided in the marinas. Nautical ports (marinas) are managed under the concessional agreements by concessionaires.",
        image:
          env.ENV === "development"
            ? require("../assets/images/port_marker_marina.svg").default
            : `${keys.BASE_URL}/wp-content/ports-map/media/port_marker_marina.svg`,
      },
      {
        label:
          "<strong>Port</strong> - refers to a seaport, i.e. a sea and land area directly connected to the sea with built and unbuilt shores, breakwaters, devices, plants, and other facilities intended for berthing, anchoring, and protection of ships, yachts, and boats, loading and unloading of passengers and goods, storage and other manipulation of goods, production, refining and finishing of goods and other economic activities that are in economic, traffic or technological connection with these activities. They are managed by the Port authorities.",
        image:
          env.ENV === "development"
            ? require("../assets/images/port_marker_small_port.svg").default
            : `${keys.BASE_URL}/wp-content/ports-map/media/port_marker_small_port.svg`,
      },
      {
        label:
          "<strong>Mooring</strong> - (nautical mooring) is a part of a specially built sea or water area for providing mooring services and accommodating vessels. They are managed under the concessional agreements by concessionaires.",
        image:
          env.ENV === "development"
            ? require("../assets/images/port_marker_docking.svg").default
            : `${keys.BASE_URL}/wp-content/ports-map/media/port_marker_docking.svg`,
      },
    ],
  },
};

export const VESSELS_LAYER: Array<Layer> = [
  {
    name: "2018_st_All_avg",
    label: "Vessel density",
    attribution:
      '&copy; <a href="https://www.emodnet-humanactivities.eu/search-results.php?dataname=Vessel+Density+">www.emodnet-humanactivities.eu</a>',
    type: "wms",
    checked: false,
    visible: false,
    wmsUrl: "https://ows.emodnet-humanactivities.eu/wms",
    legend: {
      colors: [
        {
          label:
            "GIS data on vessel density in European waters (2018 Avg). The Vessel Density maps in the EU are created since the 2019 by Cogea for the European Marine Observation and Data Network (EMODnet). The dataset is updated every year and is available for viewing and download on EMODnet Human Activities web portal (www.emodnet-humanactivities.eu). <br/><br/> Source: <a href='https://www.emodnet-humanactivities.eu/search-results.php?dataname=Vessel+Density+'>www.emodnet-humanactivities.eu</a></a>",
          image:
            env.ENV === "development"
              ? require("../assets/images/vesseldensity.png")
              : `${keys.BASE_URL}/wp-content/ports-map/media/vesseldensity.png`,
          imageHeight: "auto",
          imageWidth: "100px",
        },
        {
          label: "",
          element: () => {
            return <VesselsLayerLegendController />;
          },
        },
      ],
    },
  },
  {
    name: "2019_st_All_avg",
    label: "Vessel density",
    attribution:
      '&copy; <a href="https://www.emodnet-humanactivities.eu/search-results.php?dataname=Vessel+Density+">www.emodnet-humanactivities.eu</a>',
    type: "wms",
    checked: false,
    visible: false,
    wmsUrl: "https://ows.emodnet-humanactivities.eu/wms",
    legend: {
      colors: [
        {
          label:
            "GIS data on vessel density in European waters (2019 Avg). The Vessel Density maps in the EU are created since the 2019 by Cogea for the European Marine Observation and Data Network (EMODnet). The dataset is updated every year and is available for viewing and download on EMODnet Human Activities web portal (www.emodnet-humanactivities.eu). <br/><br/> Source: <a href='https://www.emodnet-humanactivities.eu/search-results.php?dataname=Vessel+Density+'>www.emodnet-humanactivities.eu</a></a>",
          image:
            env.ENV === "development"
              ? require("../assets/images/vesseldensity.png")
              : `${keys.BASE_URL}/wp-content/ports-map/media/vesseldensity.png`,
          imageHeight: "auto",
          imageWidth: "100px",
        },
        {
          label: "",
          element: () => {
            return <VesselsLayerLegendController />;
          },
        },
      ],
    },
  },
  {
    name: "2020_st_All_avg",
    label: "Vessel density",
    attribution:
      '&copy; <a href="https://www.emodnet-humanactivities.eu/search-results.php?dataname=Vessel+Density+">www.emodnet-humanactivities.eu</a>',
    type: "wms",
    checked: false,
    visible: true,
    wmsUrl: "https://ows.emodnet-humanactivities.eu/wms",
    legend: {
      colors: [
        {
          label:
            "GIS data on vessel density in European waters (2020 Avg). The Vessel Density maps in the EU are created since the 2019 by Cogea for the European Marine Observation and Data Network (EMODnet). The dataset is updated every year and is available for viewing and download on EMODnet Human Activities web portal (www.emodnet-humanactivities.eu). <br/><br/> Source: <a href='https://www.emodnet-humanactivities.eu/search-results.php?dataname=Vessel+Density+'>www.emodnet-humanactivities.eu</a></a>",
          image:
            env.ENV === "development"
              ? require("../assets/images/vesseldensity.png")
              : `${keys.BASE_URL}/wp-content/ports-map/media/vesseldensity.png`,
          imageHeight: "auto",
          imageWidth: "100px",
        },
        {
          label: "",
          element: () => {
            return <VesselsLayerLegendController />;
          },
        },
      ],
    },
  },
];

export const LAYERS: Array<Layer> = [
  // SHP Layers

  // GEOJSON Layers
  {
    name: "world",
    label: "Area outside the project",
    attribution: "",
    type: "geojson",
    data:
      env.ENV === "development"
        ? "./world.geojson"
        : `${keys.BASE_URL}/wp-content/ports-map/layers/world.geojson`,
    checked: true,
    visible: true,
    style: {
      fillColor: "gray",
      opacity: 0.4,
      color: "black",
      fillOpacity: 0.4,
    },
    legend: {
      colors: [
        {
          fillColor: "gray",
          borderColor: "black",
          label: "Area outside the project",
        },
      ],
    },
  },
  {
    name: "regioni_e_contee",
    label: "Project Regions and Counties",
    attribution: "",
    type: "geojson",
    data:
      env.ENV === "development"
        ? "./regioni_e_contee.geojson"
        : `${keys.BASE_URL}/wp-content/ports-map/layers/regioni_e_contee.geojson`,
    checked: false,
    visible: true,
    legend: {
      colors: [
        {
          fillColor: "transparent",
          borderColor: "black",
          label: "Project Regions and Counties",
        },
      ],
    },
    style: {
      fillColor: "transparent",
      opacity: 0.7,
      color: "black",
      fillOpacity: 0.7,
    },
  },
  {
    name: "museums",
    label: "Italian museums",
    attribution:
      '&copy; <a href="http://www.culturaitalia.it/opencms/museid/ricerca.jsp?q=*&cat=indice&openMap=true">www.culturaitalia.it</a>',
    type: "geojson",
    data:
      env.ENV === "development"
        ? "./museums.geojson"
        : `${keys.BASE_URL}/wp-content/ports-map/layers/museums.geojson`,
    checked: false,
    visible: true,
    legend: {
      colors: [
        {
          label:
            "Italian Museums. <br/><br/> Source: <a href='http://www.culturaitalia.it/opencms/museid/ricerca.jsp?q=*&cat=indice&openMap=true'>www.culturaitalia.it</a>",
          element: () => {
            return (
              <div className={"poi-container"}>
                <div className={"poi-cluster-small"}>
                  <div className={"pin-small"}></div>
                  <div className={"number"}></div>
                </div>
              </div>
            );
          },
        },
      ],
    },
    pointToLayer: (feature, latlng) => {
      return L.marker(latlng, {
        icon:
          // Temp disabled because the client asked to use only one icon for Italian Museums
          // feature.properties.cluster ?
          getIconCluster(feature.properties.label),
        // : getIconPoint(feature.properties.label),
      });
    },
    onEachFeature: function (feature, layer) {
      if (feature.properties && feature.properties.html) {
        layer.bindPopup(feature.properties.html);
      }
    },
  },

  {
    name: "hard_soft_bottom",
    label: "Hard and soft bottom shallow, shelf, slope, deep.",
    attribution:
      '&copy; <a href="http://data.tools4msp.eu">www.data.tools4msp.eu</a>',
    type: "geojson",
    data:
      env.ENV === "development"
        ? "./hard_soft_bottom.geojson"
        : `${keys.BASE_URL}/wp-content/ports-map/layers/hard_soft_bottom.geojson`,
    checked: false,
    visible: true,
    customStyle: (feature) => {
      return {
        fillColor:
          feature.properties.fid > 0 && feature.properties.fid < 1000
            ? "aqua"
            : feature.properties.fid >= 1000 && feature.properties.fid < 2000
            ? "teal"
            : feature.properties.fid >= 2000 && feature.properties.fid < 3000
            ? "blue"
            : feature.properties.fid >= 3000 && feature.properties.fid < 4000
            ? "navy"
            : feature.properties.fid >= 4000 && feature.properties.fid < 5000
            ? "red"
            : "maroon",
        opacity: 1,
        color: "transparent",
        fillOpacity: 0.3,
      };
    },
    legend: {
      colors: [
        {
          label:
            "<br/><br/> Source: <a href='http://data.tools4msp.eu'>www.data.tools4msp.eu</a>",
        },
        {
          fillColor: "aqua",
          borderColor: "transparent",
          label: "0 - 1000",
        },
        {
          fillColor: "teal",
          borderColor: "transparent",
          label: "1000 - 2000",
        },
        {
          fillColor: "blue",
          borderColor: "transparent",
          label: "2000 - 3000",
        },
        {
          fillColor: "navy",
          borderColor: "transparent",
          label: "3000 - 4000",
        },
        {
          fillColor: "red",
          borderColor: "transparent",
          label: "4000 - 5000",
        },
        {
          fillColor: "maroon",
          borderColor: "transparent",
          label: "> 5000",
        },
      ],
    },
  },

  // WMS Layers
  {
    name: "fras_2019_wgs84",
    label: "Fisheries Restricted Areas (FRAs) 2019",
    attribution:
      '&copy; <a href="http://data.tools4msp.eu/layers/geonode%3Afras_2019_wgs84">farella</a>',
    type: "wms",
    checked: false,
    visible: true,
    legend: {
      colors: [
        {
          fillColor: "#39B14B",
          borderColor: "#E79397",
          label:
            "Fisheries Restricted Areas (FRAs) from MAPAMED, the database on Sites of interest for the conservation of marine environment in the Mediterranean Sea. MedPAN, UNEP/MAP/RAC-SPA. November 2017 release. <br/><br/> Source: <a href='http://data.tools4msp.eu/layers/geonode%3Afras_2019_wgs84'>farella</a>",
        },
      ],
    },
  },
  {
    name: "emodnet_ha_environment_cdda_v15_20180430_med",
    label: "EU Mediterranean Marine Protected Areas",
    attribution:
      '&copy; <a href="http://data.tools4msp.eu/layers/geonode%3Aemodnet_ha_environment_cdda_v15_20180430_med">CNR-ISMAR</a>',
    type: "wms",
    checked: false,
    visible: true,
    legend: {
      colors: [
        {
          fillColor: "#03649B",
          borderColor: "#91B8D2",
          label:
            "Data derived from EMODNet HA 'Nationally Designated Areas', with 'Capo Testa - Punta Falcone' MPA added. <br/><br/> Source: <a href='http://data.tools4msp.eu/layers/geonode%3Aemodnet_ha_environment_cdda_v15_20180430_med'>CNR-ISMAR</a>",
        },
      ],
    },
  },
  {
    name: "world_heritage_sites_wgs84",
    label: "World Heritage Site",
    attribution:
      '&copy; <a href="http://data.tools4msp.eu/layers/geonode%3Aworld_heritage_sites_wgs84">alemulaz</a>',
    type: "wms",
    checked: false,
    visible: true,
    legend: {
      colors: [
        {
          fillColor: "#f1234a",
          borderColor: "#d62d4e",
          label:
            "World Heritage Sites from MAPAMED, the database on Sites of interest for the conservation of marine environment in the Mediterranean Sea. MedPAN, UNEP/MAP/RAC-SPA. November 2017 release. <br/><br/> Source: <a href='http://data.tools4msp.eu/layers/geonode%3Aworld_heritage_sites_wgs84'>alemulaz</a>",
        },
      ],
    },
  },

  // {
  //   name: "aree_importanti_per_lavifauna_iba",
  //   label: "Aree importanti per l’avifauna (IBA)",
  //   attribution:
  //     '&copy; <a href="https://www.pcn.minambiente.it/mattm/servizio-di-scaricamento-wfs/">www.pcn.minambiente.it</a>',
  //   type: "wms",
  //   checked: false,
  // visible: true,
  //   wmsUrl:
  //     "https://wms.pcn.minambiente.it/ogc?map=/ms_ogc/wfs/Linea_costa_2009.map ",
  // },

  {
    name: "platforms",
    label: "Offshore Installations",
    attribution:
      '&copy; <a href="https://www.emodnet-humanactivities.eu/search-results.php?dataname=Offshore+Installations">www.emodnet-humanactivities.eu</a>',
    type: "wms",
    checked: false,
    visible: true,
    wmsUrl: "https://ows.emodnet-humanactivities.eu/wms",
    legend: {
      colors: [
        {
          label:
            "The geodatabase on offshore installations in the EU was created in 2015 by Cogea for the European Marine Observation and Data Network (EMODnet). It is modelled on OSPAR's dataset on offshore installations, having the same fields and attributes. OSPAR monitors the development of offshore installations and maintains an updated inventory of all oil and gas offshore installations in the OSPAR maritime area, the OSPAR Oil and Gas Offshore inventory. <br/><br/> Source: <a href='https://www.emodnet-humanactivities.eu/search-results.php?dataname=Offshore+Installations'>www.emodnet-humanactivities.eu</a>",
          imageHeight: "0px",
          imageWidth: "0px",
          image: "",
        },
        {
          label: "Operational",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/platforms_operational.png",
        },
        {
          label: "Under Construction",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/platforms_underconstruction.png",
        },
        {
          label: "Derogation",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/platforms_derogation.png",
        },
        {
          label: "Decommissioned",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/platforms_decommissioned.png",
        },
        {
          label: "Closed",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/platforms_closed.png",
        },
      ],
    },
  },

  {
    name: "portlocations",
    label: "Main Ports",
    attribution:
      '&copy; <a href="https://www.emodnet-humanactivities.eu/search-results.php?dataname=Main+Ports">www.emodnet-humanactivities.eu</a>',
    type: "wms",
    checked: false,
    visible: true,
    wmsUrl: "https://ows.emodnet-humanactivities.eu/wms",
    legend: {
      colors: [
        {
          label:
            "The geodatabase on maritime transport in EU main ports includes goods, passengers and vessels traffic in the EU and was created in 2014 by Eurofish and Cogea for the European Marine Observation and Data Network (EMODnet). It is the result of the aggregation and harmonization of datasets provided to EUROSTAT by ports in EU Member States and Norway. EUROSTAT data have been related to GISCO's georeferenced ports. <br/><br/> Source: <a href='https://www.emodnet-humanactivities.eu/search-results.php?dataname=Main+Ports'>www.emodnet-humanactivities.eu</a>",
          imageHeight: "0px",
          imageWidth: "0px",
          image: "",
        },
        {
          label: "Traffic (Passengers, goods, vessels)",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/portnew.png",
        },
      ],
    },
  },

  {
    name: "cddaareas",
    label: "Nationally Designated Areas",
    attribution:
      '&copy; <a href="https://www.emodnet-humanactivities.eu/search-results.php?dataname=Nationally+Designated+Areas">www.emodnet-humanactivities.eu</a>',
    type: "wms",
    checked: false,
    visible: true,
    wmsUrl: "https://ows.emodnet-humanactivities.eu/wms",
    legend: {
      colors: [
        {
          label:
            "The Common Database on Designated Areas (CDDA) was created in 2014 by Cogea for the European Marine Observation and Data Network. It is entirely based on GIS Data from the European Environmental Agency's (EEA), plus external links and selected EEA tabular data joined by Cogea to the feature attributes, as well as a calculation by Cogea of marine and coastal location of features.The CDDA is commonly known as 'Nationally designated areas'. The inventory began in 1995 under the CORINE programme of the European Commission. <br/><br/> Source: <a href='https://www.emodnet-humanactivities.eu/search-results.php?dataname=Nationally+Designated+Areas'>www.emodnet-humanactivities.eu</a>",
          imageHeight: "0px",
          imageWidth: "0px",
          image: "",
        },
        {
          fillColor: "#ADC0E0",
          borderColor: "black",
          label: "Ia: Strict Nature Reserve",
        },
        {
          fillColor: "#A6D99A",
          borderColor: "black",
          label: "Ib: Wilderness Area",
        },
        {
          fillColor: "#93BFB0",
          borderColor: "black",
          label: "II: National Park",
        },
        {
          fillColor: "#8384B8",
          borderColor: "black",
          label: "III: Natural Monument or Feature",
        },
        {
          fillColor: "#DCE3C1",
          borderColor: "black",
          label: "IV: Habitat/Species Management Area",
        },
        {
          fillColor: "#AABB8F",
          borderColor: "black",
          label: "V: Protected Landscape/ Seascape",
        },
        {
          fillColor: "#DADB9C",
          borderColor: "black",
          label: "VI: Protected area with sustainable use of natural resources",
        },
        {
          fillColor: "#BAE3E2",
          borderColor: "black",
          label: "Not applicable",
        },
        {
          fillColor: "#A1E3CD",
          borderColor: "black",
          label: "Not assigned",
        },
        {
          fillColor: "#959CB8",
          borderColor: "black",
          label: "Not reported",
        },
      ],
    },
  },

  {
    name: "natura2000areas",
    label: "Natura 2000",
    attribution:
      '&copy; <a href="https://www.emodnet-humanactivities.eu/search-results.php?dataname=Natura+2000">www.emodnet-humanactivities.eu</a>',
    type: "wms",
    checked: false,
    visible: true,
    wmsUrl: "https://ows.emodnet-humanactivities.eu/wms",
    legend: {
      colors: [
        {
          image: "",
          imageHeight: "0px",
          imageWidth: "0px",
          label:
            "The dataset on Natura 2000 sites was created in 2014 by Cogea for the European Marine Observation and Data Network. It is entirely based on GIS Data from the European Environmental Agency's (EEA), plus additional info, links and selected EEA tabular data joined to the feature attributes, as well as a calculation by Cogea of marine and coastal location of features.Natura 2000 is an ecological network composed of sites designated under the Birds Directive (Special Protection Areas, SPAs) and the Habitats Directive (Sites of Community Importance, SCIs, and Special Areas of Conservation, SACs). <br/><br/> Source: <a href='https://www.emodnet-humanactivities.eu/search-results.php?dataname=Natura+2000'>www.emodnet-humanactivities.eu</a>",
        },
        {
          fillColor: "#99C7CC",
          borderColor: "black",
          label: "A: SPA (Special Protection Areas)",
        },
        {
          fillColor: "#B8C48B",
          borderColor: "black",
          label: "B: SCI (Special Conservation Interest)",
        },
        {
          fillColor: "#4455FF",
          borderColor: "black",
          label: "C: Both SPA and SCI",
        },
      ],
    },
  },

  {
    name: "bathingwaters",
    label: "State of Bathing Waters",
    attribution:
      '&copy; <a href="https://www.emodnet-humanactivities.eu/search-results.php?dataname=State+of+Bathing+Water">www.emodnet-humanactivities.eu</a>',
    type: "wms",
    checked: false,
    visible: true,
    wmsUrl: "https://ows.emodnet-humanactivities.eu/wms",
    legend: {
      colors: [
        {
          label:
            "The dataset on status of bathing water in the EU was created in 2015 by Cogea for the European Marine Observation and Data Network (EMODnet). It is based on the dataset 'Bathing Water Directive - Status of bathing water' provided by The European Topic Centre on Water and made available by the European Environment Agency at https://www.eea.europa.eu/data-and-maps/data/bathing-water-directive-status-of-bathing-water-11. <br/><br/> Source: <a href='https://www.emodnet-humanactivities.eu/search-results.php?dataname=State+of+Bathing+Water'>www.emodnet-humanactivities.eu</a>",
          imageHeight: "0px",
          imageWidth: "0px",
          image: "",
        },
        {
          label: "Excellent",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/bathingwaters_excellent.png",
        },
        {
          label: "Good",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/bathingwaters_good.png",
        },
        {
          label: "Sufficient",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/bathingwaters_sufficient.png",
        },
        {
          label: "Poor",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/bathingwaters_poor.png",
        },
        {
          label: "Not classified",
          image:
            "https://www.emodnet-humanactivities.eu/mapping/images/legend/bathingwaters_noclass.png",
        },
      ],
    },
  },

  {
    name: "emodnet:mean_multicolour",
    label: "Bathymetry",
    attribution:
      '&copy; <a href="https://emodnet.ec.europa.eu/en/emodnet-web-service-documentation">emodnet.ec.europa.eu</a>',
    type: "wms",
    checked: false,
    visible: true,
    wmsUrl: "https://ows.emodnet-bathymetry.eu/wms",
    legend: {
      colors: [
        {
          label:
            "Depth visualised in a rainbow colour style. Mean depth is based on source resolution of 1/16 arc minute (~125 meter). Ocean overlay without land cover in multi colour style. <br/><br/> Source: <a href='https://tiles.emodnet-bathymetry.eu/'>emodnet-bathymetry.eu</a>",
          image:
            env.ENV === "development"
              ? require("../assets/images/rainbow.png")
              : `${keys.BASE_URL}/wp-content/ports-map/media/rainbow.png`,
          imageHeight: "auto",
          imageWidth: "100%",
        },
      ],
    },
  },
];
