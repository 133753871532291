import ky from "ky";
import env from "../config/env";
import keys from "../config/keys";

const wpApi = ky.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export interface Port {
  id: number;
  name: string;
  lat: number;
  lng: number;
  city: string;
  boatPlaces: number;
  categoria?: string | null;
}

const mapPort = (port: any): Port => {
  return {
    id: port._ID,
    name: port.port || "NA",
    lat: port._lat,
    lng: port._lng,
    city: port.country,
    boatPlaces: port.total_number_berths,
    categoria: port.category || "Small port",
  };
};

const getPortsList = async <T extends Port>(): Promise<Port[]> => {
  const ports: T[] = await wpApi
    .get(`${keys.API_BASE_URL}${env.WP_JSON_CCT_ENDPOINT}/port`)
    .json();
  return ports.map((port) => mapPort(port));
};

const api = {
  getPortsList,
};

export default api;
