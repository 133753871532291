import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Layer } from "../../../types";

interface LayerCheckboxProps {
  layer: Layer;
  className?: string;
  updateLayers: (updatedLayer: Layer) => void;
}

export const LayerCheckbox: React.FC<LayerCheckboxProps> = ({
  layer,
  className,
  updateLayers,
}) => {
  const [checked, setChecked] = useState(layer.checked);

  const toggleChecked = useCallback(() => {
    setChecked(!checked);
    updateLayers({ ...layer, checked: !checked });
  }, [checked, layer, updateLayers]);

  return (
    <LayerController checked={checked} className={className}>
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <span className={"checkmark"} onClick={toggleChecked}></span>
      <span style={{ padding: "0 15px" }}></span>
      <CheckboxLabel>{layer.label}</CheckboxLabel>
    </LayerController>
  );
};

const LayerController = styled.div<{ checked: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  background-color: ${(props) =>
    props.checked
      ? props.theme.colors.secondaryLight
      : props.theme.colors.white};
  padding-bottom: 15px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & .checkmark {
    position: absolute;
    top: 10px;
    left: 8px;
    height: 25px;
    width: 25px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: 5px;
  }
  & input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.colors.primary};
  }

  & .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckboxLabel = styled.span`
  word-break: break-word;
  font-size: 16px !important;
`;
