import React, { useCallback } from "react";
import styled from "styled-components";
import { MapStore } from "../../stores/Map";
import theme from "../../theme";
import { Layer } from "../../types";
import { IconButton } from "../IconButton/IconButton";
import { LayerCheckbox } from "./LayerCheckbox/LayerCheckbox";

interface CustomLayersControlProps {
  showLayersControl: boolean;
  isDesktop: boolean;
  mapStore: MapStore;
  toggleShowLayersControl: () => void;
}
export const CustomLayersControl: React.FC<CustomLayersControlProps> = ({
  showLayersControl,
  isDesktop,
  mapStore,
  toggleShowLayersControl,
}) => {
  const updateLayers = useCallback(
    (layer: Layer) => {
      mapStore.setSelectedLayers(
        mapStore.selectedLayers.map((lyr) =>
          layer.name === lyr.name ? layer : lyr
        )
      );
    },
    [mapStore]
  );

  const updateVesselsLayer = useCallback(
    (layer: Layer) => {
      mapStore.setVesselsLayer(
        mapStore.vesselsLayer.map((lyr) =>
          layer.name === lyr.name ? layer : lyr
        )
      );
    },
    [mapStore]
  );

  return (
    <CustomLayersControlContainer
      showLayersControl={showLayersControl}
      isDesktop={isDesktop}
    >
      <CustomLayersControlContentContainer
        showLayersControl={showLayersControl}
      >
        <LegendTitle>
          Data
          <IconButton
            icon={"FaTimesCircle"}
            onClick={toggleShowLayersControl}
            color={theme.colors.secondary}
          />
        </LegendTitle>
        <LayersDataContainer>
          <LayerCheckbox
            layer={mapStore.portsLayer}
            updateLayers={() => {
              mapStore.setPortsLayer({
                ...mapStore.portsLayer,
                checked: !mapStore.portsLayer.checked,
              });
            }}
          />
          {mapStore.selectedLayers.map((layer, i) => (
            <LayerCheckbox
              key={layer.name}
              layer={layer}
              updateLayers={updateLayers}
            />
          ))}
          {mapStore.vesselsLayer.map(
            (layer, i) =>
              layer.visible && (
                <LayerCheckbox
                  key={layer.name}
                  className={
                    i === mapStore.vesselsLayer.length - 1
                      ? "margin-bottom-5"
                      : ""
                  }
                  layer={layer}
                  updateLayers={updateVesselsLayer}
                />
              )
          )}
        </LayersDataContainer>
      </CustomLayersControlContentContainer>
    </CustomLayersControlContainer>
  );
};

const CustomLayersControlContainer = styled.div<{
  showLayersControl: boolean;
  isDesktop: boolean;
}>`
  height: 100%;
  overflow-y: hidden;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.white};

  ${(props) =>
    props.isDesktop
      ? "position: relative;"
      : "position: absolute; top: 0px; right:0px; z-index: 1001;"};

  @keyframes reveal {
    from {
      clip-path: inset(0 0 0 100%);
      opacity: 0;
      width: 0%;
    }
    to {
      clip-path: inset(0 0 0 0);
      opacity: 1;
      width: ${(props) => (props.isDesktop ? "35%" : "50%")};
    }
  }
  @keyframes hide {
    from {
      clip-path: inset(0 0 0 0);
      opacity: 1;
      width: ${(props) => (props.isDesktop ? "35%" : "50%")};
    }
    to {
      clip-path: inset(0 0 0 100%);
      opacity: 0;
      width: 0%;
    }
  }
  animation: ${(props) => (props.showLayersControl ? "reveal" : "hide")} 0.3s
    forwards;
  animation-timing-function: ease-in-out;
`;

const CustomLayersControlContentContainer = styled.div<{
  showLayersControl: boolean;
}>`
  overflow-y: auto;
  height: 100%;
  flex-direction: column;
  display: ${(props) => (props.showLayersControl ? "flex" : "none")};
`;

const LegendTitle = styled.h4`
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.colors.primary};
  padding: 0 10px 10px 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LayersDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
