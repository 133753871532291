import { Port } from "../services/api";

export const mockedPorts: Port[] = [
  {
    id: 208,
    name: "mariclea club",
    lat: 45.5427627,
    lng: 12.7528278,
    city: "Eraclea Mare",
    boatPlaces: 2,
    categoria: "Small port",
  },
  {
    id: 209,
    name: "mariclea club2",
    lat: 45.55,
    lng: 13.75,
    city: "Test",
    boatPlaces: 12,
    categoria: "Marina",
  },
  {
    id: 210,
    name: "mariclea club3",
    lat: 43.55,
    lng: 16.75,
    city: "Test2",
    boatPlaces: 5,
    categoria: "Moorings",
  },
];
