import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Slider from "react-input-slider";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";

export const VesselsLayerLegendController: React.FC = observer(() => {
  const { map: mapStore } = useStores();

  const [sliderState, setState] = useState<2020 | 2019 | 2018>(
    parseInt(
      mapStore.vesselsLayer
        .find((el) => el.visible === true)!
        .name.split("_")[0]
    ) as 2020 | 2019 | 2018
  );

  const updateYearOfReference = useCallback(
    (sliderX: number) => {
      if (sliderX !== sliderState) {
        setState(sliderX as 2020 | 2019 | 2018);
        mapStore.isVesselsLayerLoading = true;
        mapStore.setVesselsLayer(
          mapStore.vesselsLayer.map((el) =>
            el.name.includes(sliderX.toString())
              ? { ...el, visible: true, checked: true }
              : { ...el, visible: false, checked: false }
          )
        );
      }
    },
    [mapStore, sliderState]
  );

  return (
    <Container>
      <SliderContainer>
        <SliderDescription>
          Slide to select average vessel density year of reference
        </SliderDescription>
        <SliderYears>
          {["2018", "2019", "2020"].map((year) => {
            return <span key={year}>{year}</span>;
          })}
        </SliderYears>
        <Slider
          axis="x"
          x={sliderState}
          onChange={({ x }) => updateYearOfReference(x)}
          xmin={2018}
          xmax={2020}
          xstep={1}
          disabled={mapStore.isVesselsLayerLoading}
          onDragEnd={() => {}}
          onDragStart={() => {}}
        />
      </SliderContainer>
      <span>Marine Live Traffic:</span>
      <Link
        href={"https://www.marinetraffic.com/"}
        target={"_blank"}
        rel={"noreferrer"}
      >
        <img
          src={"https://www.marinetraffic.com/img/LogoMobile@2x.png"}
          alt={"marinetraffic.com"}
          style={{ width: "34px", height: "31px" }}
        ></img>
        marinetraffic
      </Link>
      <Link
        href={"https://www.vesselfinder.com"}
        target={"_blank"}
        rel={"noreferrer"}
      >
        <img
          src={"https://static.vesselfinder.net/images/vf-logo-50x50.svg"}
          alt={"vesselfinder.com"}
          style={{ width: "34px", height: "34px" }}
        ></img>
        vesselfinder
      </Link>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
  gap: 10px;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  text-decoration: none;
`;

const SliderContainer = styled.div`
  margin-bottom: 3em;

  & div {
    max-width: 170px !important;
  }
`;

const SliderYears = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 110%;
  margin-bottom: 5px;
`;

const SliderDescription = styled.div`
  margin-bottom: 15px;
  word-break: break-word;
  text-align: justify;
`;
