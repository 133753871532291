import React from "react";
import { Layer } from "../../../types";
import { WMSTileLayer } from "react-leaflet";
import keys from "../../../config/keys";
import { MapStore } from "../../../stores/Map";

interface WMSLayerProps {
  layer: Layer;
  mapStore?: MapStore;
}

export const WMSLayer: React.FC<WMSLayerProps> = ({ layer, mapStore }) => {
  return layer.checked ? (
    <WMSTileLayer
      layers={layer.name}
      transparent={true}
      format={"image/png"}
      url={layer.wmsUrl ? layer.wmsUrl : keys.WMS_BASE_URL}
      attribution={layer.attribution}
      eventHandlers={{
        load: () => {
          if (mapStore) mapStore.isVesselsLayerLoading = false;
        },
      }}
    />
  ) : null;
};
