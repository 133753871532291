import React from "react";
import styled from "styled-components";
import { Layer } from "../../../types";

interface LegendElementProps {
  layer: Layer;
}

export const LegendElement: React.FC<LegendElementProps> = ({ layer }) => {
  return (
    <ElementContainer>
      <LayerTitle>{layer.label}</LayerTitle>
      {layer.legend &&
        layer.legend.colors.map((color) => (
          <ColorContainer key={color.label} imageWidth={color.imageWidth}>
            {color.borderColor && color.fillColor ? (
              <ColorBox
                fillColor={color.fillColor}
                borderColor={color.borderColor}
              />
            ) : color.image ? (
              <ColorImage
                src={color.image}
                alt={color.label}
                imageHeight={color.imageHeight}
                imageWidth={color.imageWidth}
              />
            ) : color.element ? (
              color.element()
            ) : null}
            <ColorLabel dangerouslySetInnerHTML={{ __html: color.label }} />
          </ColorContainer>
        ))}
    </ElementContainer>
  );
};

const LayerTitle = styled.h5`
  color: ${(props) => props.theme.colors.primary};
`;

const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
`;

const ColorContainer = styled.div<{ imageWidth?: string }>`
  display: flex;
  flex-direction: ${(props) => (props.imageWidth ? "column-reverse" : "row")};
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 40px;
`;

const ColorBox = styled.span<{ fillColor: string; borderColor: string }>`
  margin-top: 7px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-color: ${(props) => props.fillColor};
  border: 2px solid ${(props) => props.borderColor};
`;

const ColorLabel = styled.span`
  word-break: break-word;
  font-size: 16px !important;
`;

const ColorImage = styled.img<{ imageHeight?: string; imageWidth?: string }>`
  height: ${(props) =>
    props.imageHeight ? props.imageHeight : "20px"} !important;
  width: ${(props) => (props.imageWidth ? props.imageWidth : "20px")};
  max-width: 250px;
`;
