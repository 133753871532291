import { makeAutoObservable } from "mobx";
import { LAYERS, PORTS_LAYER, VESSELS_LAYER } from "../config/const";
import env from "../config/env";
import { mockedPorts } from "../mocks/mocks";
import api from "../services/api";
import { Layer, Port, Stores } from "../types";

export class MapStore {
  isLoading = true;
  isVesselsLayerLoading = false;

  portsList: Array<Port> = [];

  portsLayer: Layer = PORTS_LAYER;
  vesselsLayer: Array<Layer> = VESSELS_LAYER;
  selectedLayers: Array<Layer> = LAYERS;

  private stores: Stores;

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  initialize = async () => {
    if (env.ENV === "development") {
      this.portsList = [...mockedPorts];
    } else {
      const data = await api.getPortsList();
      if (!!data) {
        this.portsList = data;
      }
    }
    this.isLoading = false;
  };

  setSelectedLayers = (layers: Layer[]) => {
    this.selectedLayers = layers;
  };

  setPortsLayer = (layer: Layer) => {
    this.portsLayer = layer;
  };

  setVesselsLayer = (layers: Array<Layer>) => {
    this.vesselsLayer = layers;
  };
}
