import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  name: "default",
  general: {
    defaultFont: "Montserrat",
  },
  colors: {
    primary: "#164193",
    secondary: "#8BC4DF",
    secondaryLight: "#c4e1ef",
    lightGrey: "#f5f5f5",
    white: "#ffffff",
  },
};

export default theme;
