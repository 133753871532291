import React from "react";
import styled from "styled-components";

export const Layout: React.FC = ({ children }) => {
  return <StyledLayout>{children}</StyledLayout>;
};

const StyledLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 740px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  max-height: 740px;
`;
