import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Grid } from "react-loader-spinner";
import theme from "../../theme";

export const LoadingSpinner: React.FC = () => {
  return (
    <Grid
      height="100"
      width="100"
      color={theme.colors.secondary}
      ariaLabel="loading"
    />
  );
};
