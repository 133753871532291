import React, { useCallback, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { Helmet } from "react-helmet";
import { Layer, Port } from "../../types";
import {
  PORT_ICON_DOCKING,
  PORT_ICON_MARINA,
  PORT_ICON_SMALL_PORT,
} from "../../config/const";
import { PortMarker } from "../PortMarker/PortMarker";
import { WMSLayer } from "../Layers/WMSLayer/WMSLayer";
import styled from "styled-components";
import { IconButton } from "../IconButton/IconButton";
import Control from "react-leaflet-custom-control";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import { Legend } from "../Legend/Legend";
import { ShapeLayer } from "../Layers/ShapeLayer/ShapeLayer";
import { CustomLayersControl } from "../CustomLayersControl/CustomLayersControl";
import { observer } from "mobx-react";
import { useStores } from "../../hooks/useStores";
import { GeoJSONLayer } from "../Layers/GeoJSONLayer/GeoJSONLayer";
import { ZoomControl } from "../ZoomControl/ZoomControl";

interface MapProps {
  ports: Port[];
}

const MapPlaceholder = () => {
  return (
    <p>
      <noscript>You need to enable JavaScript to see this map.</noscript>
    </p>
  );
};

export const Map: React.FC<MapProps> = observer(({ ports }) => {
  const [zoomLevel, setZoomLevel] = useState(6); // initial zoom level provided for MapContainer
  const { map: mapStore } = useStores();
  const windowWidth = useWindowWidth();
  const [showLegend, setShowLegend] = useState(windowWidth >= 768);
  const [showLayersControl, setShowLayersControl] = useState(windowWidth > 768);

  const toggleShowLegend = useCallback(() => {
    setShowLegend(!showLegend);
  }, [showLegend]);

  const toggleShowLayersControl = useCallback(() => {
    setShowLayersControl(!showLayersControl);
  }, [showLayersControl]);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />
        <script
          src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
          integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
          crossOrigin=""
        ></script>
      </Helmet>
      <Legend
        showLegend={showLegend}
        toggleShowLegend={toggleShowLegend}
        isDesktop={windowWidth >= 768}
        mapStore={mapStore}
      />
      <MapContainer
        center={[windowWidth < 768 ? 41.5 : 43.5, 16]}
        zoom={windowWidth >= 768 ? 7 : 6}
        placeholder={<MapPlaceholder />}
        style={{ height: "740px", width: "100%" }}
        scrollWheelZoom={false}
      >
        <Control position="topleft">
          <ControlButton showLegend={showLegend}>
            <IconButton
              icon={"FaRegCalendar"}
              onClick={toggleShowLegend}
              size={20}
            />
          </ControlButton>
        </Control>
        <Control position="topright">
          <ControlButton showLegend={showLayersControl}>
            <IconButton
              icon={"FaLayerGroup"}
              onClick={toggleShowLayersControl}
              size={20}
            />
          </ControlButton>
        </Control>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <ZoomControl setZoomLevel={setZoomLevel} />
        {mapStore.portsLayer.checked &&
          ports.map((port) => (
            <PortMarker
              key={port.id}
              port={port}
              portIcon={
                port.categoria?.toLocaleLowerCase().includes("marina")
                  ? PORT_ICON_MARINA(zoomLevel)
                  : port.categoria?.toLocaleLowerCase().includes("mooring")
                  ? PORT_ICON_DOCKING(zoomLevel)
                  : PORT_ICON_SMALL_PORT(zoomLevel)
              }
              zoomLevel={zoomLevel}
            />
          ))}
        {mapStore.selectedLayers.map((layer: Layer) => {
          return layer.type === "wms" ? (
            <WMSLayer layer={layer} key={layer.name} />
          ) : layer.type === "shp" ? (
            <ShapeLayer layer={layer} key={layer.name} />
          ) : (
            <GeoJSONLayer layer={layer} key={layer.name} />
          );
        })}
        {mapStore.vesselsLayer.map((layer: Layer) => {
          return (
            <WMSLayer layer={layer} key={layer.name} mapStore={mapStore} />
          );
        })}
      </MapContainer>
      <CustomLayersControl
        showLayersControl={showLayersControl}
        toggleShowLayersControl={toggleShowLayersControl}
        isDesktop={windowWidth >= 768}
        mapStore={mapStore}
      />
    </>
  );
});

const ControlButton = styled.div<{ showLegend: boolean }>`
  display: ${(props) => (!props.showLegend ? "block" : "none")} !important;
  background-color: ${(props) => props.theme.colors.white} !important;
`;
