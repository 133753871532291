import React, { useCallback, useEffect, useState } from "react";
import { Layer } from "../../../types";
import { LayersControl } from "react-leaflet";
import { ShapeFile } from "react-leaflet-shapefile-v2";

interface WMSLayerProps {
  layer: Layer;
}

export const ShapeLayer: React.FC<WMSLayerProps> = ({ layer }) => {
  const [layerData, setLayerData] = useState<any>(undefined);
  const readFile = useCallback(() => {
    fetch(layer.data)
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(async function (buffer: any) {
        setLayerData(buffer);
      });
  }, [layer.data]);

  useEffect(() => {
    readFile();
  }, [readFile]);

  return (
    <LayersControl.Overlay name={layer.label} checked={true}>
      {layerData && <ShapeFile data={layerData} isArrayBufer={true} />}
    </LayersControl.Overlay>
  );
};
