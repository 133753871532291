import React from "react";
import styled from "styled-components";
import * as FontAwesome from "react-icons/fa";

interface IconButtonProps {
  icon: keyof typeof FontAwesome;
  size?: number;
  color?: string;
  onClick: () => void;
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  size = 20,
  color = "black",
  onClick,
}) => {
  const Icon = FontAwesome[icon];

  return (
    <Button onClick={onClick}>
      <Icon size={size} fill={color} />
    </Button>
  );
};

const Button = styled.button`
  border: none !important;
  background: none !important;
  cursor: pointer;
  padding: 10px !important;
`;
