import React, { useCallback, useEffect, useState } from "react";
import { Stores } from "./types";
import { StoresContext } from "./contexts";
import { MapStore } from "./stores/Map";
import { Layout } from "./components/Layout/Layout";
import { LoadingSpinner } from "./components/LoadingSpinner/LoadingSpinner";
import { Map } from "./components/Map/Map";
import { observer } from "mobx-react";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

export const App: React.FC = observer(() => {
  const [stores] = useState<Stores>(() => {
    const root: Stores = {} as Stores;
    root.map = new MapStore(root);
    return root;
  });

  const initApp = useCallback(async () => {
    await stores.map.initialize();
  }, [stores.map]);

  useEffect(() => {
    initApp();
  }, [initApp]);

  return (
    <StoresContext.Provider value={stores}>
      <ThemeProvider theme={theme}>
        <Layout>
          {stores.map.isLoading ? (
            <LoadingSpinner />
          ) : (
            <Map ports={stores.map.portsList} />
          )}
        </Layout>
      </ThemeProvider>
    </StoresContext.Provider>
  );
});
